import React from 'react';
import Seo from '../components/Seo/Seo';
import {Layout} from '../components/Layout';
import { HeroAbout } from '../components'

export default function About() {
  return <Layout>
    <Seo title="O nas" />
      <HeroAbout />
  </Layout>
}